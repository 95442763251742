





























import { Api } from '@/api'
import { Component, Vue } from 'vue-property-decorator'
import TheHeaderContainer from './components/TheHeaderContainer.vue'
import TheFooterContainer from './components/TheFooterContainer.vue'

@Component({
  components: {
    TheHeaderContainer,
    TheFooterContainer
  }
})
export default class Index extends Vue {
  loggedIn : boolean = false;

  async created (): Promise<void> {
    try {
      // null if not logged in
      const user = await Api.getCurrentUser()
      if (user) {
        this.loggedIn = true
      }
    } catch (error) {
      this.$bvModal.msgBoxOk('Could not load user: ' + error.message, { title: 'Error' })
    }
  }

  openOverview () {
    window.location.href = 'overview.html'
  }
}
